/* TODO Adicionar a fonte localmente no projeto. */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap'); */

@font-face {
  font-family: 'Inter';
  src: url('./interact/fonts/Inter/Inter-VariableFont_slnt\,wght.ttf') format('truetype');
  font-weight: 100 900;
}

@font-face {
  font-family: 'Poppins';
  src: url('./interact/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

html {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

form {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
